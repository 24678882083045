<template>
  <div>
    <v-card class="TableWrapper">
      <!-- #region table -->
      <v-simple-table fixed-header height="490px">
        <template v-slot:default>
          <thead v-if="!bLoading">
            <tr>
              <th class="text-left">
                Nombre del proveedor
              </th>
              <th class="text-end">
                Adeudo
              </th>
              <th class="text-end">
                15 Días
              </th>
              <th class="text-end">
                30 Días
              </th>
              <th class="text-end">
                60 Días
              </th>
              <th class="text-end">
                Compra anticipada disp.
              </th>
              <th class="w-145 text-center">
                Acción
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <tr translate="no" v-for="(item, index) in items" :key="index">
              <td class="text-left">{{ item.sCompanyName }}</td>
              <td class="text-end">${{ mask(item["dTotalPrices"]) }}</td>
              <td class="text-end">${{ mask(item["15-29"]) }}</td>
              <td class="text-end">${{ mask(item["30-59"]) }}</td>
              <td class="text-end">${{ mask(item["60>"]) }}</td>
              <td class="text-end color-green-global">${{ mask(item.dTotalUsedQuantity) }}</td>
              <td class="texts-center">
                <div>
                  <v-btn icon color="black" @click="viewDetails(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon color="black" @click="setEdit(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon color="#FF5757" @click="deleteProjects(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot
            v-if="!bLoading"
            style="position: sticky; bottom: 0; background: #fff"
          >
            <tr>
              <th class="text-left border-table-footer"></th>
              <th class="text-end border-table-footer color-red-global">
                ${{ mask(dTotalDebt) }}
              </th>
              <th class="text-end border-table-footer color-red-global">
                ${{ mask(dTotalDebt15To29) }}
              </th>
              <th class="text-end border-table-footer color-red-global">
                ${{ mask(dTotalDebt30To59) }}
              </th>
              <th class="text-end border-table-footer color-red-global">
                ${{ mask(dTotalDebt60Plus) }}
              </th>
              <th class="text-end border-table-footer color-green-global">
                ${{mask(dTotalUsedQuantity)}}
              </th>
              <th class="text-end border-table-footer"></th>
            </tr>
          </tfoot>
          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
      <!-- #endregion table -->

      <!-- #region footer table -->
      <div class="footer-table">
        <div style="margin-right: 30px">
          <p>
            Filas por página:
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-table-dropdown"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iItemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeTablePagination(10)">
                  <v-list-item-title>10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(25)">
                  <v-list-item-title>25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(50)">
                  <v-list-item-title>50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(100)">
                  <v-list-item-title>100</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
        <div style="margin-right: 15px">
          <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
        </div>
        <div>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="backPageContacts"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="nextPageContacts"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- #endregion footer table -->
    </v-card>
  </div>
</template>
<script>
export default {
  name: "ProvidersComponent",
  data() {
    return {
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,
      // store db items (Pickers)
      mainstatus: "En proceso",
      Estatus: ["En proceso", "Detenido", "Terminado"],
      items: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      openDialogDetails: false,
      itemDetails: {},
      openDialogDelete: false,
      itemDelete: {},
      dTotalDebt: 0,
      dTotalDebt0To14: 0,
      dTotalDebt15To29: 0,
      dTotalDebt30To59: 0,
      dTotalDebt60Plus: 0,
      dTotalUsedQuantity:0
    };
  },

  mounted() {
    this.getItems();
  },
  methods: {
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    viewDetails(item) {
      this.$store.commit("setProject", null);
      // this.$router.push("/admin/provider/" + item.sSupplierId);
      this.$router.push({
        name: "ProviderDetail",
        params: { sSupplierId: item.sSupplierId },
      });
      this.$store.commit("setIdSupplier", item.sSupplierId);
    },
    setEdit(item) {
      this.$store.commit("setEditSupplier", item);
    },
    deleteProjects(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sSupplierId,
        route: "/api/v1/supplier/" + item.sSupplierId,
        unit: " proveedor.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeTablePagination(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems();
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    // get the items from api
    getItems() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/purchase_order/supplier`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: this.iCurrentPage,
          sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          arrProjectIds: this.aProject,
          bHasDebt: this.bHasDebt,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.items = resp.data.orders;
          this.itemsTotalsDebts = resp.data;
          this.dTotalDebt = resp.data.dTotalDebt;
          this.dTotalDebt0To14 = resp.data.dTotalDebt0To14;
          this.dTotalDebt15To29 = resp.data.dTotalDebt15To29;
          this.dTotalDebt30To59 = resp.data.dTotalDebt30To59;
          this.dTotalDebt60Plus = resp.data.dTotalDebt60Plus;
          this.dTotalUsedQuantity = resp.data.dTotalUsedQuantity;
          this.totalItems = resp.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    aProject() {
      return this.$store.state.aProject;
    },
    aSupplier() {
      return this.$store.state.aSupplier;
    },
    bHasDebt() {
      return this.$store.state.bHasDebt;
    },
    sArea() {
      return this.$store.state.sArea;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    sArea: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sStatus: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    endDate: function() {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems();
      }
    },
    startDate: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    aProject: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    aSupplier: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    bHasDebt: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    iCurrentPage: function() {
      this.getItems();
    },
    refreshTable: function() {
      this.getItems();
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style scoped>
.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}
.actionsIcon {
  font-size: 20px !important;
}
.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}
</style>
